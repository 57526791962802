(function($) {
  var Sage = {};

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  jQuery(document).ready(function () {

    /*if (window.top != window) {
      $('header.banner').remove();
      $('.site-footer').remove();
    }*/

    var windowWidth = $(window).innerWidth();
    var topOfWindow = $(window).scrollTop();

    $(window).resize(function() {
      windowWidth = $(window).innerWidth();
      topOfWindow = $(window).scrollTop();
    });

    //===== Close menu on esc
    $(document).keyup(function(e) {
      if (e.keyCode === 27) { // esc
        if ($('#mainNavbarContent').hasClass('show')) {
          $('button.navbar-toggler').trigger('click');
        }
      }
    });



    //===== Header
    var headerEl = $('header.banner.remote-page-header');

    headerPosition();

    $(window).scroll(function () {
      topOfWindow = $(window).scrollTop();
      headerPosition();
    });

    function headerPosition() {
      var topOfWindow = $(window).scrollTop();

      if (topOfWindow > 1) {
        headerEl.addClass('header-scroll');
      } else {
        headerEl.removeClass('header-scroll');
      }
    }


    // Dropdown menu
    var dropdownItem = $('.menu-item-has-children');

    var dropDownFnc = function( windowWidth ) {
      // if( windowWidth > 991) {
      dropdownItem.mouseover(function() {
        var $this = $(this);
        var subMenu = $('.sub-menu');

        $this.addClass('visible');

        $this.find( subMenu ).addClass('show-dropdown');
      });

      dropdownItem.mouseleave(function () {
        dropdownItem.removeClass('visible');

        var $this = $(this);
        var subMenu = $('.sub-menu');

        $this.find(subMenu).removeClass('show-dropdown');
      });
    };

    if($('.remote-page-header').is('header')) {

      dropDownFnc = function( windowWidth ) {
        if( windowWidth > 991) {
          dropdownItem.mouseover(function() {
            var $this = $(this);
            var subMenu = $('.sub-menu');

            $this.addClass('visible');

            $this.find( subMenu ).addClass('show-dropdown');
          });

          dropdownItem.mouseleave(function () {
            dropdownItem.removeClass('visible');

            var $this = $(this);
            var subMenu = $('.sub-menu');

            $this.find(subMenu).removeClass('show-dropdown');
          });

        } else {
          var dropdownItemMob = $('.menu-item-has-children a');

          dropdownItemMob.on('click', function (e) {
            if (e.offsetX > this.offsetWidth) {
              e.preventDefault();

              var checkHeight = 36;
              var parent = $(e.target).closest('.menu-item-has-children'),
                parentHeight = $(parent).innerHeight();

              if ( parentHeight > checkHeight ) {
                dropdownItem.height(checkHeight).removeClass('mob-menu-open');
              } else {
                dropdownItem.height(checkHeight).removeClass('mob-menu-open');

                var subMenuHeight = $(parent).find('.sub-menu').innerHeight();
                $(parent).height(checkHeight + subMenuHeight + 5).addClass('mob-menu-open');
              }
            }
          });
        }
      };

      $('.navbar-toggler').on('click', function () {
        $('.remote-page-header').toggleClass('mobile-opened-menu');
      });
    }

    dropDownFnc(windowWidth);

    $(window).resize(function() {
      dropDownFnc(windowWidth);


      if( windowWidth > 991) {
        dropdownItem.height( 20 ).removeClass('mob-menu-open');
      } else {
        dropdownItem.height( 36 ).removeClass('mob-menu-open');
      }
    });


    //===== Testimonials slider
    if($('#testimonials-slider').is('div')) {
      var testimonialsSwiper = new Swiper('#testimonials-slider .slider-container', {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesOffsetAfter: 0,
        spaceBetween: 0,
        loop: true,
        roundLengths: true,
        speed: 700,
        //autoplay: false,
        autoplay: true,
        navigation: {
          nextEl: '.swiper-testimonials-controls .swiper-button-next',
          prevEl: '.swiper-testimonials-controls .swiper-button-prev'
        },
        pagination: {
          el: '.testimonials-pagination',
          type: 'bullets',
          bulletElement: 'span',
          clickable: true
        }
      });
    }


    //===== Clients slider
    if($('#speakers-slider').is('div')) {
      var clientsSwiper = new Swiper('#speakers-slider .slider-container', {
        direction: 'horizontal',
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 3,
        slidesOffsetAfter: 0,
        spaceBetween: 50,
        loop: false,
        speed: 700,
        autoplay: false,
        breakpoints: {
          991: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 30
          },
          767: {
            spaceBetween: 10,
            slidesPerView: 1,
            slidesPerGroup: 1
          }
        },
        pagination: {
          el: '.speakers-slider .simple-pagination',
          type: 'bullets',
          bulletElement: 'span',
          clickable: true
        }
      });

    }


    //Video magnific Pop-up
    $('.popup-video').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      alignTop: false,
      fixedContentPos: true,
      iframe: {
        patterns: {
          youtube: {
            src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1&showinfo=0'
          }
        }
      }
    });


    //Make Scroll if link start from #
    $('.link').on('click', function(e) {
      if( $(this).attr('href')[0] === '#') {
        e.preventDefault();

        var pageBody = $('body');
        var backTop = 0;
        if (pageBody.hasClass('admin-bar')) {
          backTop = 32;
        }

        var id = $(this).attr('href'),
            top = $(id).offset().top - backTop;

        $('body,html').animate({scrollTop: (top)}, 500);
      }
    });

    /* iframe settings */
    $('.iframe-wrapper').each(function () {
      var thisFrame = $(this);
      thisFrame.css('padding-bottom', thisFrame.width() * 0.5625 );
    });

    $(window).resize(function() {
      $('.iframe-wrapper').each(function () {
        var thisFrame = $(this);
        thisFrame.css('padding-bottom', thisFrame.width() * 0.5625 );
      });
    });


    // Clear field btn
    $('.clear-field').on('click', function(e) {
      e.preventDefault();

      $(this).closest("div").find( "input" ).val('').focus();
    });

    /*//===
    $("#videoModal").on('show.bs.modal', function() {
      $('#iframeVideoModal').attr('src', $('#iframeVideoModalUrl').text());
    });

    $("#videoModal").on('hide.bs.modal', function() {
      $('#iframeVideoModal').attr('src', '');
    });*/

  });
})(jQuery);


